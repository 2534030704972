import React from 'react'
import { motion } from 'framer-motion'
import DefaultLayout from '../layout/DefaultLayout'
import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'
import { flaschen } from '../menu'

const Flaschen = () => {
  const pageTransition1 = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  }
  const pageVariants = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
    <div className='main-container flaschen-container'>
      <DefaultLayout>
        <motion.div
          className='button-wrapper'
          initial='out'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition1}>
          <h1>Flaschen</h1>
          <h2>werden mit 2L Softdrinks serviert</h2>
        </motion.div>
        <div className='main-content-container'>
          <Container>
            {flaschen &&
              flaschen.map((f) => (
                <div className='main-content-row d-flex justify-content-between align-items-start'>
                  <div>
                    <div className='d-flex'>
                      <span className='main-text'>{f.name}</span>
                      <span className='ingrediants-text'>
                        {f.ingrediants.join(', ')}
                      </span>
                    </div>
                    <span className='description-text'>{f.description}</span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='ml-2'>
                      <span className='size-text'>0.7L</span>
                      <span className='main-text'>
                        {new Intl.NumberFormat('de-DE', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(f.price)}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </Container>
        </div>
        <Footer page='7' next='sekt-wein' prev='spirituosen' />
      </DefaultLayout>
    </div>
  )
}

export default Flaschen
