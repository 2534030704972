import React from 'react'
import { motion } from 'framer-motion'
import DefaultLayout from '../layout/DefaultLayout'
import { softdrinks } from '../menu'
import { Container } from 'react-bootstrap'
import Footer from '../components/Footer'

const Softdrinks = () => {
  const pageTransition1 = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  }
  const pageVariants = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
    <div className='main-container softdrinks-container'>
      <DefaultLayout>
        <motion.div
          className='button-wrapper'
          initial='out'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition1}>
          <h1>Softdrinks</h1>
        </motion.div>
        <Container>
          <div className='main-content-container'>
            {softdrinks &&
              softdrinks.map((s) => (
                <div className='main-content-row d-flex justify-content-between align-items-start'>
                  <div>
                    <div className='d-flex'>
                      <span className='main-text'>{s.name}</span>
                      <span className='ingrediants-text'>
                        {s.ingrediants.join(', ')}
                      </span>
                    </div>
                    <span className='description-text'>{s.description}</span>
                  </div>
                  <div className='d-flex align-items-center'>
                    {s.price.map((p) => (
                      <div className='ml-2'>
                        <span className='size-text'>{p.size}</span>
                        <span className='main-text'>
                          {new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(p.price)}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </Container>
        <Footer page='2' next='cocktails' prev='shishas' />
      </DefaultLayout>
    </div>
  )
}

export default Softdrinks
