import React from 'react'
import { motion } from 'framer-motion'
import DefaultLayout from '../layout/DefaultLayout'
import { cocktails, cocktailsNoAlk } from '../menu'
import { Container } from 'react-bootstrap'
import Footer from '../components/Footer'

const Cocktails = () => {
  const pageTransition1 = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  }
  const pageVariants = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
    <div className='main-container cocktails-container'>
      <DefaultLayout>
        <motion.div
          className='button-wrapper'
          initial='out'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition1}>
          <h1>Cocktails</h1>
          <h2>mit Alkohol</h2>
        </motion.div>
        <Container>
          <div className='main-content-container'>
            {cocktails &&
              cocktails.map((c) => (
                <div className='main-content-row d-flex justify-content-between align-items-start'>
                  <div>
                    <div className='d-flex'>
                      <span className='main-text'>{c.name}</span>
                      <span className='ingrediants-text'>
                        {c.ingrediants.join(', ')}
                      </span>
                    </div>
                    <span className='description-text'>{c.description}</span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <span className='main-text'>
                      {new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(c.price)}
                    </span>
                  </div>
                </div>
              ))}
          </div>
          <motion.div
            className='button-wrapper'
            initial='out'
            animate='in'
            exit='out'
            variants={pageVariants}
            transition={pageTransition1}>
            <h1>Cocktails</h1>
            <h2>ohne Alkohol</h2>
          </motion.div>
          <div className='main-content-container'>
            {cocktailsNoAlk &&
              cocktailsNoAlk.map((c) => (
                <div className='main-content-row d-flex justify-content-between align-items-start'>
                  <div>
                    <div className='d-flex'>
                      <span className='main-text'>{c.name}</span>
                      <span className='ingrediants-text'>
                        {c.ingrediants.join(', ')}
                      </span>
                    </div>
                    <span className='description-text'>{c.description}</span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <span className='main-text'>
                      {new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(c.price)}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </Container>
        <Footer page='3' next='longdrinks' prev='softdrinks' />
      </DefaultLayout>
    </div>
  )
}

export default Cocktails
