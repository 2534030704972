import React from 'react'
import { motion } from 'framer-motion'
import DefaultLayout from '../layout/DefaultLayout'
import { Container } from 'react-bootstrap'
import { shishas, shishasSpecial } from '../menu'
import Footer from '../components/Footer'

const Shisha = () => {
  const pageTransition1 = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  }
  const pageTransition2 = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  const pageVariants = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
    <div className='main-container shisha-container'>
      <DefaultLayout>
        <motion.div
          className='button-wrapper'
          initial='out'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition1}>
          <h1 style={{ marginBottom: 20 }}>Neue Sorten auf Anfrage!</h1>
          <h1>Shisha</h1>
          <h2>mit Badsha</h2>
        </motion.div>
        <Container>
          <motion.div
            className='button-wrapper'
            initial='out'
            animate='in'
            exit='out'
            variants={pageVariants}
            transition={pageTransition2}>
            <div className='main-content-container'>
              {shishas &&
                shishas.map((s) => (
                  <div
                    key={s._id}
                    className='main-content-row d-flex align-items-center justify-content-between'>
                    <span className='main-text'>{s.name}</span>
                    <span className='main-text'>
                      {new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(s.price)}
                    </span>
                  </div>
                ))}

              <div className='main-content-row d-flex align-items-center justify-content-between'>
                <span className='main-text'>Neuer Kopf</span>
                <span className='main-text'>7,00 €</span>
              </div>
            </div>
          </motion.div>
          <motion.div
            className='button-wrapper mt-4'
            initial='out'
            animate='in'
            exit='out'
            variants={pageVariants}
            transition={pageTransition1}>
            <h1>Shisha</h1>
            <h2>Specials mit Badsha</h2>
          </motion.div>
          <div className='main-content-container'>
            {shishasSpecial &&
              shishasSpecial.map((s) => (
                <div
                  key={s._id}
                  className='main-content-row d-flex align-items-center justify-content-between'>
                  <span className='main-text'>{s.name}</span>
                  <span className='main-text'>
                    {new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(s.price)}
                  </span>
                </div>
              ))}
            <div className='main-content-row d-flex align-items-center justify-content-between'>
              <span className='main-text'>Neuer Kopf</span>
              <span className='main-text'>8,00 €</span>
            </div>
          </div>
          <motion.div
            className='button-wrapper mt-4'
            initial='out'
            animate='in'
            exit='out'
            variants={pageVariants}
            transition={pageTransition1}>
            <h1>Extras</h1>
          </motion.div>
          <div className='main-content-container'>
            <div className='main-content-row d-flex align-items-center justify-content-between'>
              <span className='main-text'>Bazooka mit Eisaufsatz</span>
              <span className='main-text'>1,00 €</span>
            </div>
            <div className='main-content-row d-flex align-items-center justify-content-between'>
              <span className='main-text'>1KG Kohle</span>
              <span className='main-text'>7,00 €</span>
            </div>
          </div>
        </Container>
        <Footer page='1' next='softdrinks' prev='bier' />
      </DefaultLayout>
    </div>
  )
}

export default Shisha
