import React from 'react'
import { motion } from 'framer-motion'
import DefaultLayout from '../layout/DefaultLayout'
import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'
import { longdrinks } from '../menu'

const Longdrinks = () => {
  const pageTransition1 = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  }
  const pageVariants = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
    <div className='main-container longdrinks-container'>
      <DefaultLayout>
        <motion.div
          className='button-wrapper'
          initial='out'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition1}>
          <h1>Longdrinks</h1>
          <h2>4cl Alkohol je Longdrink</h2>
        </motion.div>
        <Container>
          <div className='main-content-container'>
            {longdrinks &&
              longdrinks.map((l) => (
                <div className='main-content-row d-flex justify-content-between align-items-start'>
                  <div>
                    <div className='d-flex'>
                      <span className='main-text'>{l.name}</span>
                      <span className='ingrediants-text'>
                        {l.ingrediants.join(', ')}
                      </span>
                    </div>
                    <span className='description-text'>{l.description}</span>
                  </div>
                  <span className='main-text'>
                    {new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(l.price)}
                  </span>
                </div>
              ))}
          </div>
        </Container>

        <Footer page='4' next='shooters-aperetif' prev='cocktails' />
      </DefaultLayout>
    </div>
  )
}

export default Longdrinks
