import React from 'react'
import { motion } from 'framer-motion'
import DefaultLayout from '../layout/DefaultLayout'
import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'
import { shooters, aperetif } from '../menu'

const Shooters = () => {
  const pageTransition1 = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  }
  const pageTransition2 = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  const pageVariants = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }
  return (
    <div className='main-container shooters-container'>
      <DefaultLayout>
        <motion.div
          className='button-wrapper'
          initial='out'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition1}>
          <h1>Shooters</h1>
        </motion.div>
        <Container>
          <div className='main-content-container'>
            {shooters &&
              shooters.map((s) => (
                <div className='main-content-row d-flex justify-content-between align-items-start'>
                  <div>
                    <div className='d-flex'>
                      <span className='main-text'>{s.name}</span>
                      <span className='ingrediants-text'>
                        {s.ingrediants.join(', ')}
                      </span>
                    </div>
                    <span className='description-text'>{s.description}</span>
                  </div>
                  <span className='main-text'>
                    {new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(s.price)}
                  </span>
                </div>
              ))}
          </div>
        </Container>
        <motion.div
          className='button-wrapper'
          initial='out'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition2}>
          <h1>Aperetif</h1>
        </motion.div>
        <Container>
          <div className='main-content-container'>
            {aperetif &&
              aperetif.map((a) => (
                <div className='main-content-row d-flex justify-content-between align-items-start'>
                  <div>
                    <div className='d-flex'>
                      <span className='main-text'>{a.name}</span>
                      <span className='ingrediants-text'>
                        {a.ingrediants.join(', ')}
                      </span>
                    </div>
                    <span className='description-text'>{a.description}</span>
                  </div>
                  <span className='main-text'>
                    {new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(a.price)}
                  </span>
                </div>
              ))}
          </div>
        </Container>
        <Footer page='5' next='spirituosen' prev='longdrinks' />
      </DefaultLayout>
    </div>
  )
}

export default Shooters
