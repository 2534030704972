import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import { AnimatePresence } from 'framer-motion'

// Screens
import Home from './screens/Home'
import Shisha from './screens/Shisha'
import Softdrinks from './screens/Softdrinks'
import Cocktails from './screens/Cocktails'
import Longdrinks from './screens/Longdrinks'
import Shooters from './screens/Shooters'
import Spirituosen from './screens/Spirituosen'
import Flaschen from './screens/Flaschen'
import Sekt from './screens/Sekt'
import HeisseGetraenke from './screens/HeisseGetraenke'
import Bier from './screens/Bier'
import Saefte from './screens/Saefte'

function App() {
  return (
    <AnimatePresence>
      <Router>
        <ScrollToTop />
        <Route path='/' component={Home} exact />
        <Route path='/shishas' component={Shisha} exact />
        <Route path='/softdrinks' component={Softdrinks} exact />
        <Route path='/cocktails' component={Cocktails} exact />
        <Route path='/longdrinks' component={Longdrinks} exact />
        <Route path='/shooters-aperetif' component={Shooters} exact />
        <Route path='/spirituosen' component={Spirituosen} exact />
        <Route path='/flaschen' component={Flaschen} exact />
        <Route path='/sekt-wein' component={Sekt} exact />
        <Route path='/saefte' component={Saefte} exact />
        <Route path='/heisse-getraenke' component={HeisseGetraenke} exact />
        <Route path='/bier' component={Bier} exact />
      </Router>
    </AnimatePresence>
  )
}

export default App
