import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import Logo from '../mamba-logo.png'

const Header = () => {
  return (
    <header>
      <Navbar className='py-3' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img src={Logo} alt='Logo' id='brand-logo' />
            </Navbar.Brand>
          </LinkContainer>
          <Link to='/' className='nav-menu-btn'>
            Menu
          </Link>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
