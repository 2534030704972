exports.shishas = [
  {
    _id: '1',
    name: 'Apfel',
    price: 10,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '2',
    name: 'Traube',
    price: 10,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '5',
    name: 'Pfirsisch',
    price: 10,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '6',
    name: 'Zitrone',
    price: 10,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '7',
    name: 'Orange',
    price: 10,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '8',
    name: 'Limette',
    price: 10,
    description: '',
    ingrediants: [],
    extras: '',
  },
]

exports.shishasSpecial = [
  {
    _id: '10',
    name: 'Bluemist',
    price: 11,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '11',
    name: 'Cola Kracher',
    price: 11,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '12',
    name: 'Lemon Chill',
    price: 11,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '13',
    name: 'Mango Tango',
    price: 11,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '14',
    name: 'Cinderella',
    price: 11,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '15',
    name: 'Watermelon Chill',
    price: 11,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '16',
    name: 'Love 66',
    price: 11,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '17',
    name: 'Ice Apfel',
    price: 11,
    description: '',
    ingrediants: [],
    extras: '',
  },
]

exports.saefte = [
  {
    _id: '18',
    name: 'Orangensaft',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Frisch gepresster O-Saft',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'KiBa (Nektar)',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Apfelsaft',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Bananennektar',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Ananassaft',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Mangonektar',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Maracujanektar',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Kirschnektar',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Multivitaminsaft',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Mango Banane',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
]

exports.shakes = [
  {
    _id: '17',
    name: 'Bounty',
    price: 4.9,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '17',
    name: 'KitKat',
    price: 4.9,
    description: '',
    ingrediants: ['A', 'B'],
    extras: '',
  },
  {
    _id: '17',
    name: 'Snickers',
    price: 4.9,
    description: '',
    ingrediants: ['A', 'D'],
    extras: '',
  },
  {
    _id: '17',
    name: 'Oreo',
    price: 4.9,
    description: '',
    ingrediants: ['A', 'B'],
    extras: '',
  },
  {
    _id: '17',
    name: 'Raffaello',
    price: 4.9,
    description: '',
    ingrediants: ['A', 'D'],
    extras: '',
  },
  {
    _id: '17',
    name: 'Schoko',
    price: 4.7,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '17',
    name: 'Vanille',
    price: 4.7,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '17',
    name: 'Erdbeere',
    price: 4.7,
    description: '',
    ingrediants: ['A', 'B'],
    extras: '',
  },
  {
    _id: '17',
    name: 'Banane',
    price: 4.7,
    description: '',
    ingrediants: [],
    extras: '',
  },
]

exports.softdrinks = [
  {
    _id: '18',
    name: 'Coca Cola',
    price: [
      {
        size: '0.2L',
        price: 2,
      },
      {
        size: '0.4L',
        price: 3.4,
      },
    ],
    description: '',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Cola Light',
    price: [
      {
        size: '0.2L',
        price: 2,
      },
      {
        size: '0.4L',
        price: 3.4,
      },
    ],
    description: '',
    ingrediants: ['1', '2', '5', '7', '10'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Cola Zero',
    price: [
      {
        size: '0.2L',
        price: 2,
      },
      {
        size: '0.4L',
        price: 3.4,
      },
    ],
    description: '',
    ingrediants: ['1', '2', '11'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Fanta',
    price: [
      {
        size: '0.2L',
        price: 2,
      },
      {
        size: '0.4L',
        price: 3.4,
      },
    ],
    description: '',
    ingrediants: ['10'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Sprite',
    price: [
      {
        size: '0.2L',
        price: 2,
      },
      {
        size: '0.4L',
        price: 3.4,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Mezzo Mix',
    price: [
      {
        size: '0.2L',
        price: 2,
      },
      {
        size: '0.4L',
        price: 3.4,
      },
    ],
    description: '',
    ingrediants: ['1', '2', '10'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Eistee Nestea',
    price: [
      {
        size: '0.2L',
        price: 2.5,
      },
      {
        size: '0.4L',
        price: 3.4,
      },
    ],
    description: 'Pfirsich, Waldfrucht, Mango-Ananas, Zitrone',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Ginger Ale',
    price: [
      {
        size: '0.2L',
        price: 2.3,
      },
      {
        size: '0.4L',
        price: 3.3,
      },
    ],
    description: '',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Bitter Lemon',
    price: [
      {
        size: '0.2L',
        price: 2.3,
      },
      {
        size: '0.4L',
        price: 3.3,
      },
    ],
    description: '',
    ingrediants: ['3', '9', '10'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Tonic Water',
    price: [
      {
        size: '0.2L',
        price: 2.3,
      },
      {
        size: '0.4L',
        price: 3.3,
      },
    ],
    description: '',
    ingrediants: ['3'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Mineralwasser',
    price: [
      {
        size: '0.2L',
        price: 1.8,
      },
      {
        size: '0.4L',
        price: 2.8,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Stilles Wasser',
    price: [
      {
        size: '0.2L',
        price: 1.8,
      },
      {
        size: '0.4L',
        price: 2.8,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Apfelschorle',
    price: [
      {
        size: '0.2L',
        price: 2.3,
      },
      {
        size: '0.4L',
        price: 3.2,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Club Mate',
    price: [
      {
        size: '0.33L',
        price: 2.5,
      },
    ],
    description: '',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Red Bull',
    price: [
      {
        size: '0.25L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: ['1', '2', '5', '6'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Wild Dragon',
    price: [
      {
        size: '0.25L',
        price: 2.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Fritz',
    price: [
      {
        size: '0.33L',
        price: 2.9,
      },
    ],
    description: 'Versch. Sorten',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: '28 Black',
    price: [
      {
        size: '0.25L',
        price: 3.5,
      },
    ],
    description: 'Schwarz, Grün, Gelb',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Fassbrause',
    price: [
      {
        size: '0.33L',
        price: 2.9,
      },
    ],
    description: '',
    ingrediants: ['B'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Malztrunk',
    price: [
      {
        size: '0.33L',
        price: 2.9,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Moloko',
    price: [
      {
        size: '0.25L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Tymbark',
    price: [
      {
        size: '0.33L',
        price: 2.2,
      },
    ],
    description: 'Versch. Sorten',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Aloe Vera King',
    price: [
      {
        size: '0.5L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Christinen',
    price: [
      {
        size: '0.33L',
        price: 3,
      },
    ],
    description: 'Rubin, Multi',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Orangina',
    price: [
      {
        size: '0.25L',
        price: 2.5,
      },
    ],
    description: '',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Spezi Energy',
    price: [
      {
        size: '0.33L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: '4Bro',
    price: [
      {
        size: '0.5L',
        price: 3.5,
      },
    ],
    description: 'Neue Sorten auf Anfrage',
    ingrediants: [],
    extras: '',
  },
]

exports.cocktails = [
  {
    _id: '18',
    name: 'Pina Colada',
    price: 5.9,
    description: 'Malibu (6cl), Ananassaft, Kokossirup',
    ingrediants: ['2', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Swimming Pool',
    price: 5.9,
    description:
      'Weisser Rum (4cl), Kokossirup, Blue Curacao (2cl), Ananassaft & Sahne',
    ingrediants: ['2', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Mai Tai',
    price: 5.9,
    description: 'Rum (6cl), Limejuice, Zuckersirup, Mandelsirup & Limetten',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Sex On The Beach',
    price: 5.9,
    description: 'Wodka (4cl), Peach Tree, Ananassaft & Orangensaft',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Tequila Sunrise',
    price: 5.9,
    description:
      'Weisser Tequila (4cl), Grenadinsirup, Orangensaft & Orangenscheibe',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Zombie',
    price: 5.9,
    description:
      'Weisser Rum (4cl), Brauner Rum (4cl), Old Pascas 73% (2cl), Kirschsirup, Grenadine, Limejuice & Limetten',
    ingrediants: ['2', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Long Island Iced Tea',
    price: 5.9,
    description:
      'Tequila(2cl), Brauner Rum (2cl), Wodka (2cl), Gin (2cl), Orangensaft, Limejuice, Cola & Limetten',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Caipirinha',
    price: 5.9,
    description: 'Cachaca (6cl), Limetten, Rohrzucker & Soda',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Mojito',
    price: 5.9,
    description: 'Havana Club (5cl), Limetten, Minzblätter, Rohrzucker & Soda',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Watermelon Man',
    price: 5.9,
    description:
      'Wodka (4cl), Melonenlikör (2cl), Grenadinsirup, Zitronensaft & Orangensaft',
    ingrediants: ['2'],
    extras: '',
  },
]

exports.cocktailsNoAlk = [
  {
    _id: '18',
    name: 'Virginia Colada',
    price: 4.9,
    description: '',
    ingrediants: ['2', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Coconut Kiss',
    price: 4.9,
    description: 'Ananassaft, Kokossirup & Sahne',
    ingrediants: ['2', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Aloha',
    price: 4.9,
    description: 'Orangensaft, Apfelsaft & Limejuice',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Princess',
    price: 4.9,
    description:
      'Ananassaft, Maracujasaft, Erdbeersaft, Melonensirup & Erdbeersirup',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Ipanema',
    price: 4.9,
    description: 'Limetten, Rohrzucker & Ginger Ale',
    ingrediants: ['2', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Virgin Mojito',
    price: 4.9,
    description: 'Tonic Water, Minze, Limette, Brauner RohrzuckerAnanassaft',
    ingrediants: ['3', '15'],
    extras: '',
  },
]

exports.longdrinks = [
  {
    _id: '18',
    name: 'Wodka Red Bull',
    price: 6,
    description: 'Absolut Wodka & Red Bull',
    ingrediants: ['1', '2', '5', '6'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Gin Tonic',
    price: 6,
    description: 'Gordons & Tonic Water',
    ingrediants: ['3'],
    extras: '',
  },

  {
    _id: '18',
    name: 'Jim Beam Cola',
    price: 6,
    description: 'Jim Beam & Cola',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Jack Daniels Cola',
    price: 6,
    description: 'Jack Daniels & Cola',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Johnny Walker Cola',
    price: 6,
    description: 'Johnny Walker Red Label & Cola',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Jägermeister Red Bull',
    price: 6,
    description: 'Jägermeister & Red Bull',
    ingrediants: ['1', '2', '5', '6'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Malibu Saft',
    price: 6,
    description: 'Malibu & Orangensaft',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Bacardi Cola',
    price: 6,
    description: 'Bacardi & Cola',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Campari Orange',
    price: 6,
    description: 'Campari & Orangensaft',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Cuba Libre',
    price: 6,
    description: 'Havana Club, Limette & Cola',
    ingrediants: ['1', '2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Chivas Regal Cola',
    price: 6,
    description: 'Chivas Regal & Cola',
    ingrediants: ['1', '2'],
    extras: '',
  },
]

exports.shooters = [
  {
    _id: '18',
    name: 'Hemmingway',
    price: 3,
    description: 'Gin (5cl), Orangensaft & Grenadine',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Orgasmus',
    price: 3,
    description: 'Baileys (2cl) & Sambuca (2cl)',
    ingrediants: ['2', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Wodini',
    price: 3,
    description: 'Wodka (2cl) & Bitter Lemon',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'B 52',
    price: 3.5,
    description: 'Kaluha (2cl), Baileys (2cl) & Brauner Rum (2cl)',
    ingrediants: ['2', 'A'],
    extras: '',
  },
]

exports.aperetif = [
  {
    _id: '18',
    name: 'Martini',
    price: 5,
    description: 'Extra Dry, Bianco (2cl), Rosso (2cl)',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Margarita',
    price: 5,
    description: 'Tequila (3cl), Cointreau (2cl), Zitronensaft',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Aperol Spritz',
    price: 5,
    description: 'Aperol (3cl), Sekt (6cl) & Orangensaft',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Hugo',
    price: 5,
    description: 'Sekt (2cl), Holundersirup, Minze, Limetten, Mineralwasser',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Cosmopolitan',
    price: 5,
    description: 'Wodka (3cl), Cointreau (1cl), Zitronensaft, Cranberry Saft',
    ingrediants: [],
    extras: '',
  },
]

exports.spirituosen = [
  {
    _id: '18',
    name: 'Jim Beam',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Jack Daniels',
    price: [
      {
        size: '0.02L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Johnny Walker',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: 'Black Label',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Johnny Walker',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: 'Red Label',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Ballantines',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Chivas Regal',
    price: [
      {
        size: '0.02L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Sambuca',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Jägermeister',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Absolut Wodka',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Tequila',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: 'Silver / Gold',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Baileys',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: ['2', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Ramazotti',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Berliner Luft',
    price: [
      {
        size: '0.02L',
        price: 2,
      },
      {
        size: '0.04L',
        price: 3,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Raki',
    price: [
      {
        size: '0.02L',
        price: 2.5,
      },
      {
        size: '0.04L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
]

exports.flaschen = [
  {
    _id: '18',
    name: 'Absolut Wodka',
    price: 65,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Smirnoff',
    price: 65,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Chivas Regal',
    price: 75,
    description: '',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Jack Daniels',
    price: 70,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Jim Beam',
    price: 60,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Johnny Walker',
    price: 75,
    description: 'Black Label',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Johnny Walker',
    price: 60,
    description: 'Red Label',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Ballantines',
    price: 65,
    description: '',
    ingrediants: ['2'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Grey Goose',
    price: 120,
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Belvedere',
    price: 120,
    description: '',
    ingrediants: [],
    extras: '',
  },
]

exports.bierVomFass = [
  {
    _id: '18',
    name: 'Warsteiner',
    price: [
      {
        size: '0.3L',
        price: 3.4,
      },
      {
        size: '0.5L',
        price: 3.8,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Alster',
    price: [
      {
        size: '0.3L',
        price: 3.4,
      },
      {
        size: '0.5L',
        price: 3.8,
      },
    ],
    description: 'Mit Sprite',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Radler',
    price: [
      {
        size: '0.3L',
        price: 3.4,
      },
      {
        size: '0.5L',
        price: 3.8,
      },
    ],
    description: 'Mit Fanta',
    ingrediants: ['10'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Diesel',
    price: [
      {
        size: '0.3L',
        price: 3.4,
      },
      {
        size: '0.5L',
        price: 3.8,
      },
    ],
    description: 'Mit Cola',
    ingrediants: ['1', '2'],
    extras: '',
  },
]

exports.bier = [
  {
    _id: '18',
    name: 'Heineken',
    price: [
      {
        size: '0.33L',
        price: 3.2,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Warsteiner',
    price: [
      {
        size: '0.33L',
        price: 3.2,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Becks',
    price: [
      {
        size: '0.33L',
        price: 3.2,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Becks Gold',
    price: [
      {
        size: '0.33L',
        price: 3.2,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Becks Green Lemon',
    price: [
      {
        size: '0.33L',
        price: 3.2,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Becks Ice',
    price: [
      {
        size: '0.33L',
        price: 3.2,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Becks',
    price: [
      {
        size: '0.33L',
        price: 3.2,
      },
    ],
    description: 'Alkoholfrei',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Smirnoff Ice',
    price: [
      {
        size: '0.33L',
        price: 4,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Desperados',
    price: [
      {
        size: '0.33L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Corona Extra',
    price: [
      {
        size: '0.33L',
        price: 3.5,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Schöfferhofer',
    price: [
      {
        size: '0.5L',
        price: 3.4,
      },
    ],
    description: 'Hefeweizen',
    ingrediants: ['B'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Schöfferhofer',
    price: [
      {
        size: '0.5L',
        price: 3.4,
      },
    ],
    description: 'Kristall',
    ingrediants: ['B'],
    extras: '',
  },
]

exports.sekt = [
  {
    _id: '18',
    name: 'Glas Sekt',
    price: [
      {
        size: '100ml',
        price: 2.9,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Flasche Sekt',
    price: [
      {
        size: '0,75L',
        price: 15,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
]

exports.wein = [
  {
    _id: '18',
    name: 'Rotwein',
    price: [
      {
        size: '0,2L',
        price: 3.5,
      },
    ],
    description: 'Glas',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Weisswein',
    price: [
      {
        size: '0,2L',
        price: 3.5,
      },
    ],
    description: 'Glas',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Rosewein',
    price: [
      {
        size: '0,2L',
        price: 3.5,
      },
    ],
    description: 'Glas',
    ingrediants: [],
    extras: '',
  },
]

exports.heisse = [
  {
    _id: '18',
    name: 'Kaffee',
    price: [
      {
        size: 'Tasse',
        price: 2,
      },
    ],
    description: '',
    ingrediants: ['1'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Latte Macchiato',
    price: [
      {
        size: 'Glas',
        price: 2.9,
      },
    ],
    description: '',
    ingrediants: ['1', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Milchkaffee',
    price: [
      {
        size: 'Tasse',
        price: 2.7,
      },
    ],
    description: '',
    ingrediants: ['1', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Cappucino',
    price: [
      {
        size: 'Tasse',
        price: 2.7,
      },
    ],
    description: '',
    ingrediants: ['1', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Espresso',
    price: [
      {
        size: 'Tasse',
        price: 1.9,
      },
    ],
    description: '',
    ingrediants: ['1'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Doppelter Espresso',
    price: [
      {
        size: 'Tasse',
        price: 2.9,
      },
    ],
    description: '',
    ingrediants: ['1'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Espresso Macchiato',
    price: [
      {
        size: 'Tasse',
        price: 2.1,
      },
    ],
    description: '',
    ingrediants: ['1', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Heisse Bounty',
    price: [
      {
        size: 'Glas',
        price: 3,
      },
    ],
    description: '',
    ingrediants: ['1', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Heisse Schokolade',
    price: [
      {
        size: 'Glas',
        price: 2.9,
      },
    ],
    description: 'Mit Sahne',
    ingrediants: ['1', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Weisse Schokolade',
    price: [
      {
        size: 'Glas',
        price: 2.7,
      },
    ],
    description: '',
    ingrediants: ['1', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Heisse Milch',
    price: [
      {
        size: 'Glas',
        price: 2.5,
      },
    ],
    description: 'Mit Honig',
    ingrediants: ['1', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Heisse Zitrone',
    price: [
      {
        size: 'Glas',
        price: 2.2,
      },
    ],
    description: '',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Chai Latte',
    price: [
      {
        size: 'Glas',
        price: 2.9,
      },
    ],
    description: '',
    ingrediants: ['1', 'A'],
    extras: '',
  },
  {
    _id: '18',
    name: 'Tee',
    price: [
      {
        size: 'Glas',
        price: 2.7,
      },
    ],
    description: 'Mit frischer Minze',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Tee',
    price: [
      {
        size: 'Glas',
        price: 2.2,
      },
    ],
    description: 'Versch. Sorten',
    ingrediants: [],
    extras: '',
  },
  {
    _id: '18',
    name: 'Ice Latte',
    price: [
      {
        size: 'Glas',
        price: 3,
      },
    ],
    description: '',
    ingrediants: ['1', 'A'],
    extras: '',
  },
]
