import React from 'react'
import { withRouter } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ScrollToTop from '../components/ScrollToTop'

const DefaultLayout = ({ children, history }) => {
  return (
    <>
      <Header history={history} />
      <ScrollToTop />
      {children}
    </>
  )
}

export default withRouter(DefaultLayout)
