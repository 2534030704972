import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Logo from '../mamba-logo.png'

const Home = () => {
  return (
    <div className='main-container home-container'>
      <div className='top-info'>
        <Container>
          <p>
            Ihr könnt bei uns auch Events veranstalten. Einfach anfragen unter:
          </p>
          <a href='tel:0176634343'> 0176 34343245</a>
        </Container>
      </div>
      <Container>
        <img src={Logo} alt='Logo' id='brand-logo' />
        <div className='menu-links'>
          <ul>
            <li>
              <Link to='/shishas'>Shisha</Link>
            </li>
            <li>
              <Link to='/softdrinks'>Softdrinks</Link>
            </li>
            <li>
              <Link to='/cocktails'>Cocktails</Link>
            </li>
            <li>
              <Link to='/longdrinks'>Longdrinks</Link>
            </li>
            <li>
              <Link to='/shooters-aperetif'>Shooters & Aperetif</Link>
            </li>
            <li>
              <Link to='/spirituosen'>Spirituosen</Link>
            </li>
            <li>
              <Link to='/flaschen'>Flaschen</Link>
            </li>
            <li>
              <Link to='/sekt-wein'>Sekt & Wein</Link>
            </li>
            <li>
              <Link to='/saefte'>Säfte</Link>
            </li>
            <li>
              <Link to='/heisse-getraenke'>Heiße Getränke</Link>
            </li>
            <li>
              <Link to='/bier'>Bier</Link>
            </li>
          </ul>
          <div className='social-media-container'>
            <h3>
              Folgt uns auch auf Instagram & Facebook und seid immer up-to-date:
            </h3>
            <a
              target='_blank'
              href='https://www.facebook.com/MambaLounge'
              className='d-flex align-items-center'>
              <img className='ml-auto' src='/fb-logo.png' />
              <span className='ml-2 mr-auto'>MambaLounge</span>
            </a>
            <a
              target='_blank'
              href='https://www.instagram.com/mamba_lounge2016'
              className='d-flex align-items-center'>
              <img className='ml-auto' src='/ig-logo.png' />
              <span className='ml-2  mr-auto'>mamba_lounge2016</span>
            </a>
          </div>
          <div className='ingrediants-container'>
            <p>INHALTSSTOFFE:</p>
            <p>
              1) KOFFEIN, 2) FARBSTOFF, 3) CHININ, 4) KONSERVIERUNGSSTOFFE, 5)
              MIT SÜSSUNGSMITELN, 6) TAURIN, 7) BRENNWERT 100ML {'<'} 1,1KJ, 8)
              PHOSPHAT, 9) ANTIOXIDATIONSMITTEL, 10) ASCORBINSÄURE, 11) ENTHÄLT
              EINE PHENYLQUELLE
            </p>
            <br />
            <p>ALLERGENE:</p>
            <p>A) MILCH, B) WEIZEN, C) SOJA, D) NÜSSE</p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Home
