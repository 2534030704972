import React from 'react'
import { Container } from 'react-bootstrap'
import { AiFillCaretRight, AiFillCaretLeft } from 'react-icons/ai'
import { Link } from 'react-router-dom'

const Footer = ({ page, next, prev }) => {
  return (
    <footer>
      <Container>
        <div className='d-flex align-items-center justify-content-between'>
          <Link to={`/${prev}`} className='button'>
            <AiFillCaretLeft color='#fff' fontSize='20px' />
            Zurück
          </Link>
          <span>{page}/11</span>
          <Link
            to={`/${next}`}
            className='button'
            style={{ textAlign: 'right' }}>
            Weiter
            <AiFillCaretRight color='#fff' fontSize='20px' />
          </Link>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
